html {
	position: relative;
	min-height: 100%;
}

html, body {
	margin: 0;
	padding: 0;
}

@media (min-width: 992px) {
	.pageContentWrapper {
	margin-bottom: 200px;
	}

	.footer {
		position: absolute;
		bottom: 0;
		height: 150px;
	}
}